import http from "./http.js";

async function getWarehousesList() {
  const response = await http.get("warehouses");
  return response.data;
}

async function getWarehouses(link) {
  const response = await http.get(`warehouses/${link}`);
  return response.data;
}

export {
  getWarehousesList,
  getWarehouses,
};