var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',[(!_vm.pageLoaded)?_c('app-loader',{attrs:{"title":"Загрузка данных Складов вне Украины","loading":!_vm.pageLoaded}}):_c('v-container',{staticClass:"d-flex justify-center",attrs:{"column":""}},[(!_vm.noData)?_c('v-card',{staticClass:"mt-0 mb-1 ml-0 pa-2 elevation-1",style:({
        height: ("" + (_vm.blockHeight + 'px')),
        'overflow-y': 'auto'
      }),attrs:{"tile":"","full-width":""}},[_c('v-card-title',[_c('h1',{staticClass:"h3 mb-2"},[_vm._v(" "+_vm._s(_vm.title)+" ")])]),_c('v-card-text',[_c('v-row',{staticClass:"d-flex justify-center"},[_c('div',[_c('v-img',{attrs:{"src":("" + _vm.$url + _vm.posterPath)}})],1)]),_c('v-row',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.value)}})])],1)],1):_c('v-card',{staticClass:"mt-0 mb-1 ml-0 pa-2 elevation-1",style:({
        height: ("" + (_vm.blockHeight + 'px')),
        'width': '100%',
        'overflow-y': 'auto'
      }),attrs:{"tile":"","full-width":""}},[_c('v-card-title',[_c('p',[_vm._v("Нет данных о складах вне Украины")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }